import { format } from "date-fns";
import useGetStatistics, { EModules as StatisticEModules } from "api/useGetStatistics";
import Typography from "components/Typography";
import apiDateFormat from "constants/apiDateFormat";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import { allTime } from "constants/commonDateRanges";
import styles from "pages/Home/styles.module.scss";
import useGetSales, { EModules, EModules as SalesEModules } from "api/useGetSales";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import IDateRangeFormatted from "types/IDateRangeFormatted";
import ERoutes, { EQueryParameterKeys } from "types/Routes";

// const { endDate, startDate } = lastThirtyDays;

const TopVDPByBodyStyle = () => {


  const userAuth = useAppSelector(selectUserData);
  const navigate = useNavigate();
  
  const dateRange: IDateRangeFormatted = {
    startDate: format(allTime.startDate, apiDateFormat),
    endDate: format(allTime.endDate, apiDateFormat),
  };
  
  const vdpByBodyStyleQuery = useGetSales<EModules.VDPByBodyStyleV2>({
    module: EModules.VDPByBodyStyleV2,
    params: {
      // date_from: format(startDate, apiDateFormat),
      // date_to: format(endDate, apiDateFormat),
      dealership_uid: userAuth.dealerV2.uid,
      limit: 10,
      inventory: userAuth.inventory.value,
    },
  });

  const salesQuery = useGetSales<SalesEModules.TopVDPSByModel>({
    module: SalesEModules.TopVDPSByModel,
    params: {
      dealership_uid: userAuth.dealerV2.uid,
      date_from: format(allTime.startDate, apiDateFormat),
      date_to: format(allTime.endDate, apiDateFormat),
      limit: 10,
      inventory: userAuth.inventory.value,
    },
  });

  const title = userAuth.inventory.value === "used" ? "Top VDP’s by Body Style Summary" : "Top VDP’s by Model";
  
  const onModelRowClick = (model: string) => () => {

    const queryParams = new URLSearchParams({
      ...(dateRange.startDate && dateRange.endDate && {
        [EQueryParameterKeys.StartDate]: dateRange.startDate,
        [EQueryParameterKeys.EndDate]: dateRange.endDate,
        'sortBy': '-vdps',
      }),
      [EQueryParameterKeys.Search]: model
      // [EQueryParameterKeys.]: model,
    });

    const tempUrl = `${ERoutes.VDPResults}?${queryParams}`;

    navigate(tempUrl);

  };

  const getTopVDPByModeOrStype = () => {

    if (vdpByBodyStyleQuery.isLoading || salesQuery.isLoading) {
      return 'Loading...';
    }
    if (userAuth.inventory.value === "new") {
      return (
        <>
          {salesQuery.data?.map((item, index) => (
            <Box
              display="flex"
              justifyContent="space-between"
              borderBottom="1px solid #d0d5dd"
              width="100%"
              padding="8px 0px 8px 0px"
              key={`AttentionItem-${index}`}
              cursor={"pointer"}
              onClick={onModelRowClick(item.model)}
              // className={styles["topVDP__table__item"]}
            >
              <Typography
                className={styles["topVDP__table__item--text"]}
                fontSize="text-sm"
                fontWeight="semibold"
              >
                {item.model}
              </Typography>
              <Typography
                className={styles["topVDP__table__item--text"]}
                fontSize="text-sm"
                fontWeight="semibold"
              >
                {item.total_vdps.toLocaleString()}
              </Typography>
            </Box>
          ))}
          {salesQuery.data?.length === 0 && (
            <Typography className={styles["topVDP__table__noData"]}>
              No data
            </Typography>
          )}
        </>
      );
    } else {
      return (
        <>
          {vdpByBodyStyleQuery.data?.map((item, index) => (
            <div
              key={`AttentionItem-${index}`}
              className={styles["topVDP__table__item"]}
            >
              <Typography
                className={styles["topVDP__table__item--text"]}
                fontSize="text-sm"
                fontWeight="semibold"
              >
                {item.body_style_label}
              </Typography>
              <Typography
                className={styles["topVDP__table__item--text"]}
                fontSize="text-sm"
                fontWeight="semibold"
              >
                {item.total_vdps.toLocaleString()}
              </Typography>
            </div>
          ))}
          {vdpByBodyStyleQuery.data?.length === 0 && (
            <Typography className={styles["topVDP__table__noData"]}>
              No data
            </Typography>
          )}
        </>
      );
    }
  };

  return (
    <div className={styles.topVDP}>
      <div className={styles["topVDP__table"]}>
        <div className={styles["topVDP__table__title"]}>
          <Typography
            className={styles["topVDP__table__title--text"]}
            fontSize="text-lg"
            fontWeight="bold"
          >
            {title}
          </Typography>
          {/* Temporarily disabled
      
        <Select
          className={styles["topVDP__table__select"]}
          options={BODY_STYLE_LIST}
          onChange={handleBodyStyleChange}
          placeholder="Body Style"
          isClearable
        /> */}
        </div>
        {getTopVDPByModeOrStype()}
      </div>
    </div>
  );
};

export default TopVDPByBodyStyle;
