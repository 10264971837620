import { TThirdPartyProvider } from "api/dealershipApi";
import { EOrderingReports } from "api/useGetStatistics";
import { ReactComponent as BarChartIcon } from "assets/icons/bar-chart-01.svg";
import { ReactComponent as CheckVerifiedIcon } from "assets/icons/check-verified-01.svg";
import { ReactComponent as FileIcon } from "assets/icons/file-03.svg";
import { ReactComponent as ShoppingBagIcon } from "assets/icons/shopping-bag-03.svg";
import { TInventoryOptionValue } from "constants/common";
import { UserRolesEnum } from "enums/UserRolesEnum";
import { EMarketingReports } from "types/MarketingPage";
import { EMerchandisingReports } from "types/MerchandisingPage";
import ERoutes, { EQueryParameterKeys, ERoutesAnchors } from "types/Routes";

// old inventory
export const menuItems = [
  {
    icon: FileIcon,
    label: "Results Summary",
    link: ERoutes.Home,
  },
  {
    icon: ShoppingBagIcon,
    label: "Merchandising",
    subMenu: [
      {
        label: "Deal Ratings by Channel",
        link: `${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${EMerchandisingReports.DealRatings}#${ERoutesAnchors.MerchandisingTables}`,
      },
      {
        label: "Tip Report",
        link: `${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${EMerchandisingReports.TipReport}#${ERoutesAnchors.MerchandisingTables}`,
      },
      {
        label: "Channel Stats Report",
        link: `${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${EMerchandisingReports.ChannelStats}#${ERoutesAnchors.MerchandisingTables}`,
      },
      {
        label: "Pre Shoot Report",
        link: `${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${EMerchandisingReports.PreShootReport}#${ERoutesAnchors.MerchandisingTables}`,
      },
    ],
  },
  {
    icon: CheckVerifiedIcon,
    label: "VDP Results",
    link: ERoutes.VDPResults,
  },
  {
    icon: FileIcon,
    label: "Reporting",
    subMenu: [
      {
        label: "Leads",
        link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.MostLeads}#${ERoutesAnchors.ReportingTables}`,
      },
      {
        label: "Most VDPs",
        link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.MostVDPs}#${ERoutesAnchors.ReportingTables}`,
      },
      {
        label: "Least VDPs (10+DOL)",
        link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.LeastVDPs}#${ERoutesAnchors.ReportingTables}`,
      },
      {
        label: "Days on Lot",
        link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.DaysOnLot}#${ERoutesAnchors.ReportingTables}`,
      },
      {
        label: "VDP Boost",
        link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.VDPBoost}#${ERoutesAnchors.ReportingTables}`,
      }
    ],
  },
  {
    icon: BarChartIcon,
    label: "Marketing Results",
    subMenu: [
      {
        label: "Budget ROI",
        link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.BudgetROI}`,
      },
      {
        label: "VDPs by Channel",
        link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.VDPsByChannel}`,
      },
      {
        label: "Top 10 Website Sources",
        link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.TopTenWebsiteSources}`,
      },
      {
        label: "VDP ROI",
        link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.VDPROI}`,
      },
      //{
      //  label: "Lead ROI",
      //  link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.LeadROI}`,
      //},
    ],
  },
];

// new inventory

export const newMenuItems = [
  {
    icon: FileIcon,
    label: "Results Summary",
    link: ERoutes.Home,
  },
  {
    icon: ShoppingBagIcon,
    label: "Merchandising",
    subMenu: [
      {
        label: "Channel Stats Report",
        link: `${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${EMerchandisingReports.ChannelStats}#${ERoutesAnchors.MerchandisingTables}`,
      },
      {
        label: "Pre Shoot Report",
        link: `${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${EMerchandisingReports.PreShootReport}#${ERoutesAnchors.MerchandisingTables}`,
      },
    ],
  },
  {
    icon: CheckVerifiedIcon,
    label: "VDP Results",
    link: ERoutes.VDPResults,
  },
  {
    icon: FileIcon,
    label: "Reporting",
    subMenu: [
      {
        label: "Leads",
        link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.MostLeads}#${ERoutesAnchors.ReportingTables}`,
      },
      {
        label: "Most VDPs",
        link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.MostVDPs}#${ERoutesAnchors.ReportingTables}`,
      },
      {
        label: "Least VDPs (10+DOL)",
        link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.LeastVDPs}#${ERoutesAnchors.ReportingTables}`,
      },
      {
        label: "Days on Lot",
        link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.DaysOnLot}#${ERoutesAnchors.ReportingTables}`,
      },
      {
        label: "VDP Boost",
        link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.VDPBoost}#${ERoutesAnchors.ReportingTables}`,
      }
    ],
  },
  {
    icon: BarChartIcon,
    label: "Marketing Results",
    subMenu: [
      {
        label: "Budget ROI",
        link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.BudgetROI}`,
      },
      {
        label: "VDPs by Channel",
        link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.VDPsByChannel}`,
      },
      {
        label: "Top 10 Website Sources",
        link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.TopTenWebsiteSources}`,
      },
      {
        label: "VDP ROI",
        link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.VDPROI}`,
      },
      //{
      //  label: "Lead ROI",
      //  link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.LeadROI}`,
      //},
    ],
  },
];

export const getMainMenuItems = (inventory: TInventoryOptionValue, roles: UserRolesEnum[], third_party_provider: TThirdPartyProvider[]) => {
  
  return ([
    {
      icon: FileIcon,
      label: "Results Summary",
      link: ERoutes.Home,
    },
    {
      icon: ShoppingBagIcon,
      label: "Merchandising",
      subMenu: [
        ...(inventory === 'used' && third_party_provider.length > 0 ? [
          {
            label: "Deal Ratings by Channel",
            link: `${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${EMerchandisingReports.DealRatings}#${ERoutesAnchors.MerchandisingTables}`,
          },
          {
            label: "Tip Report",
            link: `${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${EMerchandisingReports.TipReport}#${ERoutesAnchors.MerchandisingTables}`,
          },
        ] : []),
        {
          label: "Channel Stats Report",
          link: `${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${EMerchandisingReports.ChannelStats}#${ERoutesAnchors.MerchandisingTables}`,
        },
        {
          label: "Pre Shoot Report",
          link: `${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${EMerchandisingReports.PreShootReport}#${ERoutesAnchors.MerchandisingTables}`,
        },
      ],
    },
    {
      icon: CheckVerifiedIcon,
      label: "VDP Results",
      link: ERoutes.VDPResults,
    },
    {
      icon: FileIcon,
      label: "Reporting",
      subMenu: [
        {
          label: "Leads",
          link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.MostLeads}#${ERoutesAnchors.ReportingTables}`,
        },
        {
          label: "Most VDPs",
          link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.MostVDPs}#${ERoutesAnchors.ReportingTables}`,
        },
        {
          label: "Least VDPs (10+DOL)",
          link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.LeastVDPs}#${ERoutesAnchors.ReportingTables}`,
        },
        {
          label: "Days on Lot",
          link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.DaysOnLot}#${ERoutesAnchors.ReportingTables}`,
        },
        {
          label: "VDP Boost",
          link: `${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${EOrderingReports.VDPBoost}#${ERoutesAnchors.ReportingTables}`,
        }
      ],
    },
    {
      icon: BarChartIcon,
      label: "Marketing Results",
      subMenu: [
        ...(third_party_provider.length > 0 ? [
          {
            label: "Budget ROI",
            link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.BudgetROI}`,
          },
          {
            label: "VDPs by Channel",
            link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.VDPsByChannel}`,
          },
        ] : []),
        {
          label: "Top 10 Website Sources",
          link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.TopTenWebsiteSources}`,
        },
        {
          label: "VDP ROI",
          link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.VDPROI}`,
        },
        // {
        //   label: "Lead ROI",
        //   link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.LeadROI}`,
        // },
        ...(roles.includes(UserRolesEnum.SuperAdmin) ? [
          {
            label: "Internal Reporting",
            link: `${ERoutes.MarketingResults}?${EQueryParameterKeys.ActiveTable}=${EMarketingReports.InternalReporting}`,
          },
        ] : [])
      ],
    },
  ]);
};